import { nanoid } from "nanoid";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import { getObjectFromEntries } from "./general";
import { ANALYTICS_MESSAGES } from "../constants";
import { readContentDataFromStorage, SessionStorageService } from ".";
const UTM_PARAMS = [
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
    "utm_term"
];
const UTM_PARAMS_UC = [
    "utm_campaign_uc",
    "utm_content_uc",
    "utm_medium_uc",
    "utm_program_uc",
    "utm_source_uc",
    "utm_term_uc"
];
const HOST_NAMES_WITH_REVENUE_ENTITY = [
    "deals.today.com",
    "marketplace.today.com"
];
const MP_EXT_ID = "mp_ext_id";
const DURATION_MP_EXT_ID_DAYS = 30;
const SHOPPABLE_MOMENT_ID = "shoppableMomentId";
const SESSION_REFERRING_PAGE_KEY = "usc-referrer-page";
const SESSION_SUB_BRAND_NAME_KEY = "sub-brand-name";
var OptimizelyEvents;
(function (OptimizelyEvents) {
    OptimizelyEvents["REVENUE"] = "trackRevenue";
    OptimizelyEvents["ORDERS"] = "orders";
    OptimizelyEvents["ITEMS"] = "items";
})(OptimizelyEvents || (OptimizelyEvents = {}));
function getUtmParams(isUC) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const parameters = isUC ? UTM_PARAMS_UC : [...UTM_PARAMS, ...UTM_PARAMS_UC];
    return parameters.reduce((accumulated, param) => (Object.assign(Object.assign({}, accumulated), (urlSearchParams.has(param) && {
        [param]: urlSearchParams.get(param)
    }))), {});
}
function getShoppableMomentId() {
    var _a;
    const url = new URL(window.location.href);
    const shoppableMomentId = decodeURIComponent((_a = url.searchParams.get(SHOPPABLE_MOMENT_ID)) !== null && _a !== void 0 ? _a : "");
    if (shoppableMomentId) {
        return { shoppableMomentId };
    }
    return {};
}
function getPageSlug(str) {
    var _a;
    const arr = str.split("/").filter(e => e);
    return (_a = arr[arr.length - 1]) !== null && _a !== void 0 ? _a : "";
}
function getCustomAttributes(analyticsData) {
    return Object.entries(analyticsData)
        .map(([key, value]) => `${key}:${value || ""}`)
        .join(";");
}
function parseCustomAttributes(attributes) {
    return getObjectFromEntries(attributes.split(";").map(pair => pair.split(":")));
}
function getProductInfoAttributes(product) {
    return {
        productId: `${product === null || product === void 0 ? void 0 : product.channelKey}:${product.productKey}`,
        productName: (product === null || product === void 0 ? void 0 : product.name) || null,
        sellerId: (product === null || product === void 0 ? void 0 : product.channelKey) || null,
        sellerName: (product === null || product === void 0 ? void 0 : product.seller) || null,
        sponsorName: (product === null || product === void 0 ? void 0 : product.brandName) || null
    };
}
function getSponsorEventAttributes(parsedAnalyticsDataAttributes, productInfoAttributes) {
    const pageSlug = getPageSlug(window.location.pathname);
    const pageName = document.title;
    const domain = window.location.host;
    const url = window.location.toString();
    const { contentName, contentType } = readContentDataFromStorage();
    return Object.assign(Object.assign(Object.assign(Object.assign({ contentName,
        contentType,
        pageSlug,
        pageName,
        domain,
        url }, productInfoAttributes), parsedAnalyticsDataAttributes), getShoppableMomentId()), getUtmParams(true));
}
function addOptimizelyEvent(eventName, value) {
    window.optimizely = window.optimizely || [];
    const tags = eventName === OptimizelyEvents.REVENUE
        ? {
            revenue: value
        }
        : { value };
    window.optimizely.push({
        type: "event",
        eventName,
        tags
    });
}
function logError(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
error, attrs, errorOrigin) {
    console.error("USC:", error);
    if (!window.mParticle) {
        console.error("USC: [mParticle] analyticsLogError not sent.");
        return;
    }
    const errorWithOrigin = errorOrigin
        ? Object.assign(Object.assign({}, error), { message: `${errorOrigin}: ${error.message}` }) : error;
    try {
        // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle%20&%20mParticleInstance.html#method_logError
        window.mParticle.logError(errorWithOrigin, Object.assign({ sourceUrl: window.location.href }, attrs));
    }
    catch (e) {
        console.error("USC: [mParticle] analyticsLogError error sending event.", e);
    }
}
export function logEmptyRevenueEntityError(revenueEntity, eventName, metadata) {
    if (!revenueEntity &&
        HOST_NAMES_WITH_REVENUE_ENTITY.includes(window.location.hostname)) {
        logError({
            message: `RevenueEntity on domain ${window.location.hostname} (ON ${eventName}}): on product with: ${JSON.stringify(metadata)} was not found.`
        });
    }
}
/**
 * Checks if hostnames are the same for provided urls
 */
function areTheSameHostnames(strUrlA, strUrlB) {
    let urlA;
    let urlB;
    if (strUrlA === "" || strUrlB === "") {
        return undefined;
    }
    try {
        urlA = new URL(strUrlA);
        urlB = new URL(strUrlB);
    }
    catch (error) {
        logError(error);
        return undefined;
    }
    if (urlA !== undefined && urlB !== undefined) {
        return urlA.hostname === urlB.hostname;
    }
    return undefined;
}
function enabled() {
    return !!window.mParticle;
}
function setReferringPage(referringPage) {
    var _a;
    const currentReferringPage = window.sessionStorage.getItem(SESSION_REFERRING_PAGE_KEY);
    if (isNil(currentReferringPage)) {
        const currentUrl = (_a = window === null || window === void 0 ? void 0 : window.location.href) !== null && _a !== void 0 ? _a : "";
        const areSame = areTheSameHostnames(currentUrl, referringPage);
        // If hosts are the same or an error occured checking hosts, we save empty string
        if (isUndefined(areSame) || areSame) {
            SessionStorageService.setItem(SESSION_REFERRING_PAGE_KEY, "");
        }
        else {
            SessionStorageService.setItem(SESSION_REFERRING_PAGE_KEY, referringPage);
        }
    }
}
function getCommonAttributes() {
    const referringPage = SessionStorageService.getItem(SESSION_REFERRING_PAGE_KEY) || "";
    const subBrand = SessionStorageService.getItem(SESSION_SUB_BRAND_NAME_KEY) || "";
    return Object.assign({ domain: window.location.host, referringPage }, (Boolean(subBrand) && { subBrand }));
}
function logCustomEvent(name, type, attributes) {
    if (!enabled()) {
        console.log(ANALYTICS_MESSAGES.analyticsLogCustomEvent.info);
        return;
    }
    const customFlags = {
        "Facebook.EventSourceUrl": window.location.href
    };
    const data = Object.assign(Object.assign({}, getCommonAttributes()), attributes);
    try {
        // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle%20&%20mParticleInstance.html#method_logEvent
        window.mParticle.logEvent(name, type, data, customFlags);
    }
    catch (e) {
        console.error(ANALYTICS_MESSAGES.analyticsLogCustomEvent.error, e);
    }
}
function getMiraklProductCategoriesAttributes(miraklCategories) {
    var _a;
    const categoriesKeys = ((_a = miraklCategories === null || miraklCategories === void 0 ? void 0 : miraklCategories.find(category => category.miraklCategoriesKeys)) === null || _a === void 0 ? void 0 : _a.miraklCategoriesKeys) || [];
    return categoriesKeys.reduce((prevCategoryKey, categoryKey, index) => (Object.assign(Object.assign({}, prevCategoryKey), { [`L${index + 1}_category`]: categoryKey })), {});
}
function saveMpExtIdToCookie(mpExtId) {
    const date = new Date();
    date.setDate(date.getDate() + DURATION_MP_EXT_ID_DAYS);
    document.cookie = `${MP_EXT_ID}=${mpExtId}; expires=${date.toString()}`;
}
function getMpExtIdFromCookie() {
    const mpExtIdCookie = document.cookie
        .split(";")
        .find(cookie => cookie.includes(MP_EXT_ID));
    return mpExtIdCookie && mpExtIdCookie.split("=")[1];
}
function getMparticleId() {
    var _a, _b, _c, _d, _e;
    return (((_b = (_a = window.mParticle) === null || _a === void 0 ? void 0 : _a.Identity) === null || _b === void 0 ? void 0 : _b.getCurrentUser) &&
        ((_e = (_d = (_c = window.mParticle) === null || _c === void 0 ? void 0 : _c.Identity) === null || _d === void 0 ? void 0 : _d.getCurrentUser()) === null || _e === void 0 ? void 0 : _e.getMPID()));
}
function getSessionId() {
    const urlParams = new URLSearchParams(window.location.search);
    const mpExtId = urlParams.get(MP_EXT_ID);
    if (mpExtId) {
        saveMpExtIdToCookie(mpExtId);
        return mpExtId;
    }
    const mpExtIdCookie = getMpExtIdFromCookie();
    if (mpExtIdCookie) {
        return mpExtIdCookie;
    }
    return getMparticleId() || nanoid();
}
export default {
    OptimizelyEvents,
    addOptimizelyEvent,
    enabled,
    getUtmParams,
    getPageSlug,
    getMiraklProductCategoriesAttributes,
    getCustomAttributes,
    getProductInfoAttributes,
    getSponsorEventAttributes,
    getSessionId,
    getShoppableMomentId,
    logError,
    logCustomEvent,
    parseCustomAttributes,
    getCommonAttributes,
    setReferringPage,
    areTheSameHostnames,
    logEmptyRevenueEntityError
};
