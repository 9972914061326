import { ContentType } from "common/lib/constants";
import { CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY } from "../constants/components.registry";
import {
    PDP_LOCATION_HASH_PREFIX,
    parseQuery,
    RefProductMetadata
} from "./url";

export const PRODUCT_KEY_ATTRIBUTE = "product-key";
export const CHANNEL_KEY_ATTRIBUTE = "product-channel-key";
export const PRODUCT_SKU_ATTRIBUTE = "product-sku";
export const SUPPRESS_WIDGET_OPENING = "suppress-widget-opening";
export const PRODUCT_MERCHANDISE_ATTRIBUTES = "product-merchandise-attributes";
export const SPONSORSHIP_ATTRIBUTE = "nbcu-checkout-sponsor";
export const ANALYTICS_CUSTOM_ATTRIBUTES = "analytics-custom-attributes";
export const CITRUS_AD_ID = "product-ad-id";
export const BANNER_AD_ID = "banner-ad-id";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mpscall?: any;
    }
}

export interface ProductPlacement {
    productKey?: string;
    channelKey: string;
    productSku?: string;
    adId?: string;
}

export function getShoppableElements() {
    return document.querySelectorAll(
        `[data-${PRODUCT_KEY_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}], [data-${PRODUCT_SKU_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}]`
    );
}

export function getShoppableAnchors() {
    return document.querySelectorAll<HTMLAnchorElement>(
        `[href*='${PDP_LOCATION_HASH_PREFIX}']`
    );
}

export const getShoppableElementsAndLinks = () =>
    document.querySelectorAll<HTMLElement>(
        `[data-${PRODUCT_KEY_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}], [data-${PRODUCT_SKU_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}], [href*='${PDP_LOCATION_HASH_PREFIX}']`
    );

export const getSponsorshipBannerElementsAndLinks = () =>
    document.querySelectorAll<HTMLElement>(`[data-${SPONSORSHIP_ATTRIBUTE}]`);

export const getSponsorshipCitrusElementsAndLinks = () =>
    document.querySelectorAll<HTMLElement>(`[data-${CITRUS_AD_ID}]`);

export const getBannerAdsElements = () =>
    document.querySelectorAll<HTMLElement>(`[data-${BANNER_AD_ID}]`);

export const getIdentityScriptElements = (): NodeList => {
    return document.querySelectorAll('script[src*="identity-sdk.js"]');
};

export const checkIsAnchorTagWithPdpPrefix = (
    element: Element
): element is HTMLLinkElement =>
    element.tagName === "A" &&
    Boolean(element.getAttribute("href")?.includes(PDP_LOCATION_HASH_PREFIX));

export function getShoppableProducts(
    iframeProducts: ProductPlacement[]
): ProductPlacement[] {
    const shoppableElsMarkedWithDataAttrs: ProductPlacement[] = Array.from(
        getShoppableElements()
    ).map(a => ({
        productKey:
            a.attributes.getNamedItem(`data-${PRODUCT_KEY_ATTRIBUTE}`)?.value ||
            "",
        channelKey:
            a.attributes.getNamedItem(`data-${CHANNEL_KEY_ATTRIBUTE}`)?.value ||
            "",
        productSku:
            a.attributes.getNamedItem(`data-${PRODUCT_SKU_ATTRIBUTE}`)?.value ||
            "",
        adId: a.attributes.getNamedItem(`data-${CITRUS_AD_ID}`)?.value || ""
    }));

    const shoppableAnchors: ProductPlacement[] = [];

    Array.from(getShoppableAnchors()).forEach((a: HTMLAnchorElement) => {
        const queryString = a.href.split(`${PDP_LOCATION_HASH_PREFIX}?`)[1];
        if (queryString) {
            const metadata = parseQuery<RefProductMetadata>(queryString);
            if (
                metadata &&
                metadata["channel-key"] &&
                (metadata["product-key"] || metadata["product-sku"])
            ) {
                shoppableAnchors.push({
                    channelKey: metadata["channel-key"],
                    productKey: metadata["product-key"],
                    productSku: metadata["product-sku"]
                });
            }
        }
    });

    const allShoppableElements = [
        ...shoppableElsMarkedWithDataAttrs,
        ...shoppableAnchors
    ];

    const queryString = window.location.hash.split(
        `${PDP_LOCATION_HASH_PREFIX}?`
    )[1];
    if (queryString && allShoppableElements.length > 0) {
        const metadata = parseQuery<RefProductMetadata>(queryString);
        if (
            metadata &&
            metadata["channel-key"] &&
            (metadata["product-key"] || metadata["product-sku"])
        ) {
            allShoppableElements.push({
                channelKey: metadata["channel-key"],
                productKey: metadata["product-key"],
                productSku: metadata["product-sku"]
            });
        }
    }

    return iframeProducts.concat(
        allShoppableElements.sort(({ adId }) => (adId ? -1 : 1))
    );
}
const hasOnlyCcwProducts = (): boolean =>
    !Array.from(getShoppableElementsAndLinks()).some(
        element => element.dataset.contentType !== ContentType.CCW
    );

export const hasRequiredElements = (
    iframeProducts: ProductPlacement[],
    networkBrand: string
): boolean => {
    const hasShoppableProducts =
        getShoppableProducts(iframeProducts).length > 0;

    // Solution is hardcoded for "nbcu-online". In future it will be controlled by config and BTSM
    return networkBrand === "nbcu-eonline"
        ? hasShoppableProducts && !hasOnlyCcwProducts()
        : hasShoppableProducts;
};

export const hasShoppableElementsAndLinks = (): boolean =>
    !!getShoppableElementsAndLinks().length;

export const hasSponsorshipElementsAndLinks = (): boolean =>
    !!getSponsorshipBannerElementsAndLinks().length;

export const hasBannerAdsElements = (): boolean =>
    !!getBannerAdsElements().length;

export const hasListacleElements = (): boolean => {
    return Array.from(getShoppableElementsAndLinks()).some(element => {
        const { contentType } = element.dataset;
        return (
            contentType !== ContentType.CCW && contentType !== ContentType.EPW
        );
    });
};

export const hasWirewaxVideo = (): boolean =>
    !!(
        document.querySelector(`iframe[src*="wirewax.com"]`) ||
        document.querySelector(`iframe[data-src*="wirewax.com"]`)
    );

export const updateBodyClassList = (isHidden: boolean) => {
    const isMobileScreen = document.body.clientWidth <= 768;
    const contains = document.body.classList.contains(
        CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY
    );

    if (isHidden && contains) {
        document.body.classList.remove(CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY);
        if (isMobileScreen) {
            const scrollY = document.body.style.top;
            document.body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        }
    }

    if (!isHidden && !contains) {
        if (isMobileScreen) {
            document.body.style.top = `-${window.scrollY}px`;
        }
        document.body.classList.add(CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY);
    }
};

export const isPageWhitelisted = (tags: string[]) =>
    !window.mpscall ||
    tags.length === 0 ||
    tags.some(tag => window.mpscall[`cag[functional-tag]`]?.includes(tag));
