import { Analytics } from "..";
import { ContentType } from "../../constants/analytics";
import { LocalStorageService } from "../localStorageService";
export const USC_DATA_STORAGE_WRAPPER = "usc-data-storage";
const CONTENT_DATA_STORAGE = "contentData";
const CCW_PRODUCTS_STORAGE = "contextualProductsStorage";
const RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE = "productKeys";
const SPONSORSHIP_STORAGE = "sponsorshipStorage";
const SPONSORED_PRODUCTS_STORAGE = "sponsoredProductsStorage";
const DEFAULT_CONTENT_TYPE = "Web Page";
const parse = (stringifiedJSON) => {
    try {
        return JSON.parse(stringifiedJSON);
    }
    catch (error) {
        Analytics.logError(error);
        return null;
    }
};
const getUscDataStorage = () => {
    var _a;
    if (typeof window === "undefined") {
        return {};
    }
    const dataStorageWrapper = LocalStorageService.getItem(USC_DATA_STORAGE_WRAPPER);
    if (!dataStorageWrapper) {
        return {};
    }
    return (_a = parse(dataStorageWrapper)) !== null && _a !== void 0 ? _a : {};
};
export const getDefaultContentData = () => {
    var _a, _b;
    if ((_b = (_a = window.uscWidget) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.getDefaultContentData) {
        const { contentName, contentType, sponsorName } = window.uscWidget.config.getDefaultContentData();
        return Object.assign({ contentName,
            contentType }, (sponsorName && {
            sponsorName
        }));
    }
    return {
        contentName: document.title,
        contentType: DEFAULT_CONTENT_TYPE
    };
};
export const readContentDataFromStorage = () => {
    var _a;
    const initValue = getDefaultContentData();
    const uscData = getUscDataStorage();
    return (_a = uscData[CONTENT_DATA_STORAGE]) !== null && _a !== void 0 ? _a : initValue;
};
export const writeContentDataByTypeToStorage = (content) => {
    const initValue = getDefaultContentData();
    const uscWidgetData = getUscDataStorage();
    switch (content === null || content === void 0 ? void 0 : content.type) {
        case ContentType.CCW:
            uscWidgetData[CCW_PRODUCTS_STORAGE] = content.products || [];
            break;
        case ContentType.SPONSORSHIP_BANNER:
            uscWidgetData[SPONSORSHIP_STORAGE] = {
                pageSegmentName: content.pageSegmentName || null
            };
            break;
        case ContentType.SPONSORED_PRODUCTS:
            uscWidgetData[SPONSORED_PRODUCTS_STORAGE] =
                content.products.split(",");
            break;
        case ContentType.AD:
            uscWidgetData[SPONSORED_PRODUCTS_STORAGE] =
                content.products.split(",");
            uscWidgetData[CONTENT_DATA_STORAGE] = {
                contentType: ContentType.AD,
                contentName: ""
            };
            break;
        default:
            uscWidgetData[CONTENT_DATA_STORAGE] = {
                contentType: (content === null || content === void 0 ? void 0 : content.type) || initValue.contentType,
                contentName: (content === null || content === void 0 ? void 0 : content.name) || initValue.contentName
            };
    }
    LocalStorageService.setItem(USC_DATA_STORAGE_WRAPPER, JSON.stringify(uscWidgetData));
};
export const writeContentDataToStorage = (content) => {
    const initValue = getDefaultContentData();
    const uscWidgetData = getUscDataStorage();
    uscWidgetData[CONTENT_DATA_STORAGE] = {
        contentType: (content === null || content === void 0 ? void 0 : content.type) || initValue.contentType,
        contentName: (content === null || content === void 0 ? void 0 : content.name) || initValue.contentName
    };
    LocalStorageService.setItem(USC_DATA_STORAGE_WRAPPER, JSON.stringify(uscWidgetData));
};
export const readResponsiveAdsProductKeysFromStorage = () => {
    var _a, _b;
    const uscWidgetData = getUscDataStorage();
    let products = (_a = uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE]) !== null && _a !== void 0 ? _a : [];
    if (typeof products === "string") {
        products =
            (_b = parse(uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE])) !== null && _b !== void 0 ? _b : [];
    }
    return products;
};
export const writeResponsiveAdsProductKeyToStorage = (productKey) => {
    const uscWidgetData = getUscDataStorage();
    const productKeys = (uscWidgetData === null || uscWidgetData === void 0 ? void 0 : uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE]) || [];
    uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE] = Array.from(new Set([...productKeys, productKey]));
    LocalStorageService.setItem(USC_DATA_STORAGE_WRAPPER, JSON.stringify(uscWidgetData));
};
export const clearResponsiveAdsProductKeysStorage = () => {
    const uscWidgetData = getUscDataStorage();
    if (uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE]) {
        uscWidgetData[RESPONSIVE_ADS_PRODUCT_KEYS_STORAGE] = [];
    }
    LocalStorageService.setItem(USC_DATA_STORAGE_WRAPPER, JSON.stringify(uscWidgetData));
};
export const readSponsorshipDataFromStorage = () => {
    var _a;
    const initValue = {
        pageSegmentName: null
    };
    const uscWidgetData = getUscDataStorage();
    return ((_a = uscWidgetData[SPONSORSHIP_STORAGE]) !== null && _a !== void 0 ? _a : initValue);
};
export const readCcwContentDataFromStorage = () => {
    var _a, _b;
    const uscWidgetData = getUscDataStorage();
    let contentData = (_a = uscWidgetData[CCW_PRODUCTS_STORAGE]) !== null && _a !== void 0 ? _a : [];
    if (typeof contentData === "string") {
        contentData = (_b = parse(uscWidgetData[CCW_PRODUCTS_STORAGE])) !== null && _b !== void 0 ? _b : [];
    }
    return contentData;
};
export const readSponsoredProductsFromStorage = () => {
    var _a, _b;
    const uscWidgetData = getUscDataStorage();
    let products = (_a = uscWidgetData[SPONSORED_PRODUCTS_STORAGE]) !== null && _a !== void 0 ? _a : [];
    if (typeof products === "string") {
        products = (_b = parse(uscWidgetData[SPONSORED_PRODUCTS_STORAGE])) !== null && _b !== void 0 ? _b : [];
    }
    return products;
};
